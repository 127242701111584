import { FunctionComponent, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';

import Gift from '../../scss/images/gift.svg';
import { useConfig } from '../hooks';

// interface GiftSkinData {
//   getProfile: ProfileType;
// }

const GiftSkin: FunctionComponent = () => {
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const getConfig = useConfig();

  // const { loading, error, data } = useQuery<GiftSkinData>(gql`
  //   query GiftSkin {
  //     getProfile {
  //       id
  //       getWheelStep
  //       getLastFreeGame {
  //         id
  //       }
  //     }
  //   }
  // `);

  const reachGoal = (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset ||
      // eslint-disable-next-line no-unsafe-optional-chaining
      (event.target as HTMLElement)?.dataset,
  ) => {
    yametrica.reachGoal(reachgoal);
  };

  return (
    <Link
      to={getConfig?.seo?.vkDist}
      target="_blank"
      className={'gift-skin'}
      data-reachgoal="click_gift"
      onClick={reachGoal}
    >
      <span className="gift-skin-bg"></span>
      {/* <span className="text">{t('Gift skin')}</span> */}
      <span className="text">{t('Gift skin')}</span>
      <span className="gift-wrap">
        <picture>
          <img className="gift-shadow" src={Gift} alt="" />
        </picture>
      </span>
      <span className="border"></span>
    </Link>
  );
};

export default GiftSkin;
