import { FunctionComponent, SyntheticEvent, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Profile as ProfileType } from '~types';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import playAudio from '~frontend/utils/playAudio';
import { useYaMetrica } from '~frontend/provider/yametrica';

// import IconCircle from '../../scss/images/bg-circle-btn-5.png';
import circleBg from '../../scss/images/bg-wheel.svg';
import circleShadow from '../../scss/images/wheel-shadow.svg';
import circleAnimate from '../../scss/images/wheel.svg';
import circleShadowMob from '../../scss/images/wheel-shadow-mob.svg';
import circleBgMob from '../../scss/images/bg-wheel-mob.svg';
import circleAnimateMob from '../../scss/images/wheel-mob.svg';
import { useConfig } from '../hooks';
import _ from 'lodash';

interface WheelBlockData {
  getProfile: ProfileType;
}

const WheelBlock: FunctionComponent = () => {
  const { t } = useTranslation();
  const getConfig = useConfig();
  const [cookies] = useCookies(['bloggerAuth', 'HideWheel']);
  const yametrica = useYaMetrica();

  const { loading, error, data } = useQuery<WheelBlockData>(gql`
    query WheelBlock {
      getProfile {
        id
        getWheelStep
        getLastFreeGame {
          id
        }
      }
    }
  `);

  const getLastFreeGame = data?.getProfile?.getLastFreeGame;
  const getWheelStep = data?.getProfile?.getWheelStep;

  const reachGoal = (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset ||
      (event.target as HTMLElement)?.dataset,
  ) => {
    yametrica.reachGoal(reachgoal);
  };

  useEffect(() => {
    const element = document.querySelector(".case-item[data-type='3']");

    if (getLastFreeGame) {
      if (element) {
        element.classList.remove('light');
      }
    } else {
      if (element) {
        element.classList.add('light');
      }
    }
  }, [getLastFreeGame]);

  if (loading) {
    return null;
  } else if (error) {
    return <>WheelBlock | Error! {error.message}</>;
  }

  if (
    _.size(getConfig?.seo?.vkDist) > 0 ||
    cookies?.bloggerAuth ||
    cookies?.HideWheel
  ) {
    return null;
  }

  return (
    <Link
      to="/wheel"
      className={
        getWheelStep === 4
          ? 'circle-banner round_whell stop'
          : 'circle-banner round_whell active'
      }
      data-reachgoal="click_wheel"
      onClick={reachGoal}
      onMouseEnter={playAudio}
      data-audio="2.mp3"
      data-volume="0.2"
    >
      <span className="circle-banner-bg"></span>
      <span className="text">{t('Free wheel')}</span>
      <span className="circle-wrap">
        <picture>
          <source media="(max-width: 767px)" srcSet={circleShadowMob} />
          <img className="wheel-shadow" src={circleShadow} alt="" />
        </picture>
        <picture>
          <source media="(max-width: 767px)" srcSet={circleBgMob} />
          <img className="wheel-bg" src={circleBg} alt="" />
        </picture>
        <picture>
          <source media="(max-width: 767px)" srcSet={circleAnimateMob} />
          <img className="wheel-animate" src={circleAnimate} alt="" />
        </picture>
      </span>
      <span className="border"></span>
    </Link>
  );
};

export default WheelBlock;
