import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CategoriesView,
  WheelBlock,
  DiscountBanner,
  EventBanner,
  BattlePassBanner,
  Stream,
  Distributions,
  GiftSkin,
} from '../components/home';
import { useProfile } from '~components/profile/hooks';
import { useConfig } from '~components/hooks';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import BgHome2 from '../scss/images/bg-home-2.webp';
import BgHome2_768 from '../scss/images/bg-home-2-768px.webp';
import BgHome2_480 from '../scss/images/bg-home-2-480px.webp';

import '../scss/pages/_home.scss';

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const getProfile = useProfile();
  const getConfig = useConfig();

  const schema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: getConfig?.seo?.title,
      description: getConfig?.seo?.description,
      image: 'https://cdn.cscase.co/cscase/1050csgoskinshover.png',
      sku: 1,
      brand: {
        '@type': 'Brand',
        name: 'CSCASE',
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '5',
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: `${t('User_few')} CSCASE`,
        },
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        ratingCount: 1827,
      },
      offers: {
        '@type': 'AggregateOffer',
        offerCount: '100',
        lowPrice: 5,
        highPrice: 1499,
        priceCurrency: getConfig?.getCurrency?.char,
      },
    }),
  };

  const isDiscount =
    getConfig?.getSaleTime && (getConfig.isAnonDiscount || getConfig?.discount);

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  return (
    <>
      <Helmet script={[schema]} />
      {!isMobile && <WheelBlock />}
      <Stream />
      {/* <CategoryNavigation /> */}
      {isDiscount ? (
        <section className="main-bannes-sales">
          <DiscountBanner />
        </section>
      ) : null}

      {_.size(getConfig?.seo?.vkDist) > 0 && (
        // <a
        //   className="knife-block"
        //   href={getConfig?.seo?.vkDist}
        //   target="_blank"
        //   rel="noreferrer"
        // >
        //   <div className="knife-block-inside"></div>
        // </a>
        <GiftSkin />
      )}

      {getConfig?.seo?.distributionActive && <Distributions />}

      {getConfig?.getBattlePass ? (
        <section
          className={`open-cases ${
            !getProfile && 'open-cases-home'
          } open-cases-breezy open-cases-battle-pass`}
        >
          <BattlePassBanner />
        </section>
      ) : getConfig?.getEvent ? (
        <section
          className={`open-cases ${
            !getProfile && 'open-cases-home'
          } open-cases-event open-cases-breezy`}
        >
          <EventBanner />
        </section>
      ) : (
        !getProfile && (
          <section className="open-cases open-cases-home">
            <img
              className="banner-img"
              src={BgHome2}
              srcSet={`${BgHome2_480} 480w, ${BgHome2_768} 768w, ${BgHome2} 1920w`}
              sizes="(max-width: 480px) 480px, (max-width: 768px) 768px, 1920px"
              alt="Banner image"
            />
            <section>
              <h1
                dangerouslySetInnerHTML={{
                  __html: t('Open Counter-Strike Global Offensive cases'),
                }}
              ></h1>
              <h3>{t('Withdraw prizes to yourself on Steam')}!</h3>
            </section>
          </section>
        )
      )}

      <CategoriesView />
    </>
  );
};

export default Home;
